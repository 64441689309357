.box {
    width: 50%;
    text-align: center;
    background-color: rgb(226, 219, 233);
    border: 5px solid;
    padding: 20px;
    color: black;
    font-size: larger;
    font-weight: 450;
    color: black;
    height: 250%;
    margin: auto;
    margin-top: 3%;
}

.button {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}
.score-box {
    width: 20%;
    border: 2px solid;
    padding: 20px;
    font-size: larger;
    font-weight: 450;
    height: 250%;
    background-color: #4CAF50;
    color: white;
}
